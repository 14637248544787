<template>
    <div class="page2">
        <Row class='content'>
            <Col span="8">
            <div class="list" style="height: 91%">
                <div class="right" style="height: 100%">
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <div class="chart-100">
                        <!--<china-map ref="chinaMap"></china-map>-->
                        <dashBoardChart ref="char12"></dashBoardChart>
                        <div class="dd-box">
                            <div class="box">
                                <p>需求总数量</p>
                                <p class="pt" id="schoolSum">15</p>
                            </div>
                            <div class="box">
                                <p>招聘完成率</p>
                                <p class="pt">70%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Col>
            <Col span="8">
            <div class="list">
                <div class="right" style="height: 90%">
                    <span class='title'><span class="title-8">需求分类</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <div class="chart-100">
                        <CategoryChart ref="char12"></CategoryChart>
                    </div>

                </div>
            </div>

            <div class="list">
                <div class="right" style="height: 90%">
                    <span class='title'><span class="title-8">招聘渠道简历统计</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <hdFoundChart ref="chart9"></hdFoundChart>
                    <div class="info-box">
                    </div>
                </div>
            </div>

            </Col>
            <Col span="8">
            <div class="list">
                <div class="right">
                    <span class='title'><span class="title-4">需求公布</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <div class="bb-box" id="demandDynamic" style="width: 350px;">
                        <!--                  <div class="info-box" id="info-box2">-->
                        <!--                        <img src="../assets/images/banner.png" @click="screen();" alt="">-->
                        <marquee behavior="scroll" direction="up" scrollamount="1" height="120">
                            <p style="color:white;">【发布时间：】2022-07-06<br> 【客户：复星集团】<br>【城市：上海】<br> 【项目名称：门户网站】高级Java</p>
                            <p style="color:white;">【发布时间：】2022-07-06<br> 【客户：太平金科】<br>【城市：深圳】<br>【项目名称：询报价系统】高级Java</p>
                            <p style="color:white;">【发布时间：】2022-06-06<br> 【客户：北京中华】<br>【城市：北京】<br> 【项目名称：新理赔系统】高级Java</p>
                            <p style="color:white;">【发布时间：】2022-06-06 <br>【客户：浙商保险】<br>【城市：杭州】<br>【项目名称：报价系统】高级前端</p>
                            <p style="color:white;">【发布时间：】2022-05-06 <br>【客户：北&nbsp;部&nbsp;湾】<br>【城市：南宁】<br> 【项目名称：移动系统】高级前端</p>
                            <p style="color:white;">【发布时间：】2022-05-06 <br>【客户：杜邦保险】<br>【城市：北京】<br>【项目名称：理赔系统】高级前端 </p>
                        </marquee>

                    </div>

                    <div class="dd-box">
                        <div class="box1">
                            <p>最新发布</p>
                            <p class="pt">10</p>
                        </div>
                        <!--<div class="box1">
                             <p>简历总数</p>
                             <p class="pt">78</p>
                         </div>-->
                    </div>

                </div>
            </div>
            <div class="list">
                <div class="right">
                    <span class='title'><span class="title-8">招聘人员信息</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <div class="cc-box">
                        <div class="line">
                            <p style="color: white">负责人：<span id="realName">黄爱东</span></p>
                            <p style="color: white">部门：<span id="companyName">人事行政部</span></p>
                        </div>
                        <div class="line flex">
                            <p style="color: white">招聘专员：<span id="secureName">程敏</span></p>
                            <p style="color: white">需求数：<span id="empNums">15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                        </div>
                        <div class="line flex">
                            <p style="color: white">办公地址：<span id="compAddress">武汉市东湖新技术开发区</span></p>
                        </div>
                        <div class="line flex">
                            <p style="color: white">联系方式：<span id="compPhone">18695850088</span></p>
                        </div>
                    </div>
                </div>
            </div>

            </Col>
        </Row>
        <Row class="bottom-list">
            <Col span="8">
            <div class="list right-bottom">
                <div class="bottom bottom1">
                    <span class='title'><span class="title-10">简历实时动态</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <pie-chart ref="chart12" id="bottom_4"></pie-chart>
                    <div class="info-box" id="info-box">
                        <marquee behavior="scroll" direction="up" scrollamount="1" height="120">
                            <p style="color:white;">2022-07-06 7:13:53 <br>【复星集团-人力外包（2022）】<br>【简历录入：刘全】<br> 智联招聘</p>
                            <p style="color:white;">2022-07-06 9:13:53 <br>【复星集团-人力外包（2022）】<br>【简历录入：张三】<br> 智联招聘</p>
                            <p style="color:white;">2022-07-06 8:13:53 <br>【复星集团-人力外包（2022）】<br>【简历录入：武三思】<br> 智联招聘</p>
                            <p style="color:white;">2022-07-05 11:13:53 <br>【复星集团-人力外包（2022）】<br>【简历录入：李世民】<br> 51job</p>
                            <p style="color:white;">2022-07-05 16:13:53 <br>【复星集团-人力外包（2022）】<br>【简历录入：武三思】<br> 51job</p>
                            <p style="color:white;">2022-07-05 10:13:53<br> 【复星集团-人力外包（2022）】<br>【简历录入：李世民】<br> 51job</p>
                        </marquee>
                    </div>
                </div>
            </div>
            </Col>
            <Col span="8">
            <div class="list">
                <div class="bottom">
                    <span class='title'><span class="title-10">近半年招聘趋势图</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <hiddenTroubleTrend ref="chart9" id="bottom_1"></hiddenTroubleTrend>
                </div>
            </div>
            </Col>
            <Col span="8">
            <div class="list">
                <div class="bottom">
                    <span class='title'><span class="title-10">客户简历统计</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <!--<three-bar-chart ref="chart11" id="bottom_3" style="width: 75%"></three-bar-chart>-->
                    <hdScreenChart ref="chart12" id="bottom_1"></hdScreenChart>
                    <div class="dd-box">
                        <!--<div class="box1">
                            <p>客户总数</p>
                            <p class="pt">8</p>
                        </div>-->
                       <!-- <div class="box">
                            <p>简历总数</p>
                            <p class="pt">78</p>
                        </div>-->
                    </div>
                </div>
            </div>
            </Col>
        </Row>
    </div>
</template>

<script>
    const webBar = () => import('./components/page1/webBar.vue');
    const doubleLine = () => import('./components/page2/doubleLine');
    const hiddenTroubleTrend = () => import('./components/page2/hiddenTroubleTrend');
    const threeBarChart = () => import('./components/page2/threeBarChart');
    const pieChart = () => import('./components/page2/pieChart');
    const chinaMap = () => import('./components/page2/chinaMap');
    const dashBoardChart = () => import('./components/page2/dashboardChart');
    const CategoryChart = () => import('./components/page2/CategoryChart');
    const hdFoundChart = () => import('./components/page2/hdFoundChart');
    const hdScreenChart = () => import('./components/page2/hdScreenChart');

    export default {
        name: 'page2',
        props: ['selectRangeDate'],
        components: {
            // radarChart,
            // doubleBarChart,
            // singleAreaChart,
            hdScreenChart,
            hdFoundChart,
            CategoryChart,
            webBar,
            hiddenTroubleTrend,
            doubleLine,
            threeBarChart,
            dashBoardChart,
            pieChart,
            chinaMap
        },
        data() {
            return {
                everyPer: 0,
                xOffset: 0,
                circle: {
                    x: 250,
                    y: 250,
                    radius: 218
                },
                title: ['隐患暴漏总数: 21', '隐患待整改数: 3', '整改率: 85%', '巡查点总数: 106', '待巡查点数: 22', '执行率: 79%'],
                cnfigData1: {
                    color: '#5CB1C1',
                    name: ['（次）', '（人）'],
                    data: [
                        {
                            name: '聊天次数',
                            color: ['#9e70ff', '#6e5eff'],
                            data: [200, 12, 21, 54, 260, 130, 210],
                        },
                        {
                            name: '聊天人数',
                            color: ['#48cefd', '#5356f1'],
                            data: [50, 182, 234, 191, 190, 30, 10],
                        }
                    ]
                },
                configData2: {
                    data: [213, 190, 137, 99, 63, 196, 248, 212, 248, 112]
                },
                chatRadarData: {
                    title: '各部门聊天对比',
                    position: ['center', '85%'],
                    center: ['50%', '50%'],
                    indicator: [
                        {text: '一部'},
                        {text: '二部'},
                        {text: '三部'},
                        {text: '四部'},
                        {text: '五部'},
                        {text: '六部'}
                    ],
                    data: [
                        {
                            name: '聊天次数',
                            color: '#0DF5F8',
                            value: [100, 8, 0.40, -80, 2000, 332],
                        },
                        {
                            name: '聊天人数',
                            color: '#7921AD',
                            value: [60, 5, 0.30, -100, 1500, 221],
                        }
                    ]
                },
                officeRadarData: {
                    title: '各部门办公时长对比',
                    position: ['center', '85%'],
                    center: ['50%', '50%'],
                    indicator: [
                        {text: '一部'},
                        {text: '二部'},
                        {text: '三部'},
                        {text: '四部'},
                        {text: '五部'},
                        {text: '六部'}
                    ],
                    data: [
                        {
                            name: '办公时长',
                            color: '#55D35B',
                            value: [100, 8, 0.40, -80, 2000, 332],
                        }
                    ]
                },
                friendRadarData: {
                    title: '各部门好友数量对比',
                    position: ['center', '85%'],
                    center: ['50%', '50%'],
                    indicator: [
                        {text: '一部'},
                        {text: '二部'},
                        {text: '三部'},
                        {text: '四部'},
                        {text: '五部'},
                        {text: '六部'}
                    ],
                    data: [
                        {
                            name: '好友总数',
                            color: '#FA8486',
                            value: [100, 8, 0.40, -80, 2000, 332],
                        }
                    ]
                },
                momentsRadarData: {
                    title: '各部门朋友圈数量对比',
                    position: ['center', '85%'],
                    center: ['50%', '50%'],
                    indicator: [
                        {text: '一部'},
                        {text: '二部'},
                        {text: '三部'},
                        {text: '四部'},
                        {text: '五部'},
                        {text: '六部'}
                    ],
                    data: [
                        {
                            name: '朋友圈个数',
                            color: '#D91748',
                            value: [100, 8, 0.40, -80, 2000, 332],
                        }
                    ]
                },
                warea: {x: 250, y: 250, max: 700},
                dots: [],
                resizeFn: null,
                animationFrame1: null,
                animationFrame2: null,
                centerBox: {
                    width: 0,
                    height: 0
                }
            }
        },
        methods: {

            //跳转大屏
            screen() {
                var targetUrl = "http://portal.luojie-safe.cn/static/visualScreen/index.html?t=" + decodeURIComponent(sessionStorage.getItem("t"));
                targetUrl = targetUrl.replace("#/page2", "");
                sessionStorage.removeItem("t");
                window.location.href = targetUrl;
            },

            //教育局信息初始化加载
            infoInit() {
                this.$http
                    .get('https://api.luojie-safe.cn/api/user/info')
                    .then(
                        response => {//successcallback
                            //alert("成功！"+JSON.stringify(response));
                            //alert("https成功！"+JSON.stringify(response.body.data));
                            document.getElementById("realName").innerHTML = response.body.data.realName;
                            document.getElementById("companyName").innerHTML = response.body.data.companyName;
                            document.getElementById("secureName").innerHTML = response.body.data.realName;
                            document.getElementById("empNums").innerHTML = "56&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n" +
                                "                              &nbsp;&nbsp;&nbsp;&nbsp;";
                            document.getElementById("compAddress").innerHTML = response.body.data.compAddress;
                            document.getElementById("compPhone").innerHTML = response.body.data.compPhone;
                            console.log(JSON.stringify(response))//返回结果数据

                            //加载简历实时动态
                            this.resumeDynamic();
                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })
            },

            //简历实时动态
            resumeDynamic() {
                this.$http
                    .get('https://hr.estar-info.com/hrApiController.do?resumeDynamic')
                    .then(
                        response => {//successcallback
                            //alert("成功！"+JSON.stringify(response));
                            //<p style="color:white;">2022-07-06 7:13:53 【复星集团-人力外包（2022）】【简历录入：刘全】 智联招聘</p>

                            var str = "<marquee behavior=\"scroll\" direction=\"up\" scrollamount=\"1\" height=\"120\">";

                            for (var i = 0; i < response.body.obj.length; i++) {
                                var object = response.body.obj[i];
                                str += "<p style=\"color:white;\">【跟踪时间】：20" + object.createTime +
                                    "<br>【项目名称】：" + object.projectName +
                                    "<br>【应聘人员】：" + object.realName +
                                    "<br>【简历流转】：<font color='#2ecf4f'>" + object.processState  +"</font>"+
                                    "<br>【简历渠道】：" + object.channelName+
                                    "</p><br>";
                            }
                            str += "</marquee>";

                            document.getElementById('info-box').innerHTML = str;

                            //需求动态
                            this.demandDynamic();

                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })

            },

            //需求实时动态
            demandDynamic() {
                this.$http
                    .get('https://hr.estar-info.com/hrApiController.do?demandDynamic')
                    .then(
                        response => {//successcallback
                            //alert("成功！"+JSON.stringify(response));
                            // <p style="color:white;">2022-07-06  【复星集团】【城市：上海】 【门户网站】高级Java</p>

                            // {
                            //     "createTime": "22-06-14 05:50:12",
                            //     "projectName": "北部湾-人力外包(2022)",
                            //     "siteName": "北部湾保险大厦",
                            //     "job": "Android开发",
                            //     "workAdd": "南宁",
                            //     "level": "中级",
                            //     "realName": "陈欢"
                            // },

                            var str = "<marquee behavior=\"scroll\" direction=\"up\" scrollamount=\"1\" height=\"120\">";

                            for (var i = 0; i < response.body.obj.length; i++) {
                                var object = response.body.obj[i];
                                str += " <p style=\"color:white;\">【发布时间】：20" + object.createTime +
                                    "<br>【客户名称】：" + object.customerName + "【职场】：" + object.siteName + "" +
                                    "<br>【工作地点】：" + object.workAdd + "" +
                                    "<br>【项目名称】：" + object.projectName + "" +
                                    "<br>【人员需求】："+ object.job + "-" + object.level + "</p><br>";
                            }
                            str += "</marquee>";
                            document.getElementById('demandDynamic').innerHTML = str;


                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })

            },

            schoolSum() {
                this.$http
                    .get('https://api.luojie-safe.cn/api/v2/hiddenTrouble/schoolSum')
                    .then(
                        response => {//successcallback
                            //var schoolSum=  response.body.data.length;
                            // document.getElementById("schoolSum").innerHTML=schoolSum;
                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })
            },

            drawDot() {
                let canvas = document.getElementById('dot');
                canvas.width = document.querySelector('#circlePie').offsetWidth;
                canvas.height = document.querySelector('#circlePie').offsetHeight;
                let ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                // 将鼠标坐标添加进去，产生一个用于比对距离的点数组
                let ndots = [this.warea].concat(this.dots);
                this.dots.forEach((dot) => {
                    // 粒子位移
                    dot.x += dot.xa;
                    dot.y += dot.ya;
                    // 遇到边界将加速度反向
                    dot.xa *= (dot.x > canvas.width || dot.x < 0) ? -1 : 1;
                    dot.ya *= (dot.y > canvas.height || dot.y < 0) ? -1 : 1;
                    // 绘制点
                    ctx.fillStyle = '#ffffff';
                    ctx.beginPath();
                    ctx.arc(dot.x - 0.5, dot.y - 0.5, 2, 0, 2 * Math.PI, true);
                    ctx.closePath();
                    ctx.fill();
                    // 循环比对粒子间的距离
                    for (let i = 0; i < ndots.length; i++) {
                        let d2 = ndots[i];
                        if (dot === d2 || d2.x === null || d2.y === null) continue;
                        let xc = dot.x - d2.x;
                        let yc = dot.y - d2.y;
                        // 两个粒子之间的距离
                        let dis = Math.sqrt(xc * xc + yc * yc);
                        // 距离比
                        let ratio;
                        // 如果两个粒子之间的距离小于粒子对象的max值，则在两个粒子间画线
                        if (dis < d2.max) {
                            // 计算距离比
                            ratio = (d2.max - dis) / d2.max;
                            // 画线
                            ctx.beginPath();
                            ctx.lineWidth = ratio / 2;
                            if (d2 === this.warea) {
                                ctx.strokeStyle = 'rgba(255,255,255,0)';
                            } else {
                                // 距离变大 连线颜色变浅
                                ctx.strokeStyle = 'rgba(255,255,255,' + (ratio + 0.2) + ')';
                            }
                            ctx.moveTo(dot.x, dot.y);
                            ctx.lineTo(d2.x, d2.y);
                            ctx.stroke();
                        }
                    }
                    // 将已经计算过的粒子从数组中删除
                    ndots.splice(ndots.indexOf(dot), 1);
                });
                this.animationFrame1 = window.requestAnimationFrame(this.drawDot);
            },
            rads(x) { // 弧度转换
                return Math.PI * x / 180;
            },
            act() {
                //清空画布
                const canvas = document.querySelector('#main');
                canvas.style.width = this.centerBox.height + 'px';
                canvas.style.height = this.centerBox.height + 'px';
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
                this.drawPie(this.everyPer, context);
                this.animationFrame2 = window.requestAnimationFrame(this.act);
                this.everyPer += Math.PI / 180;
                let speed = 0.07; //波浪速度，数越大速度越快
                this.xOffset += speed;
            },
            drawPie(everyPer, context) {
                context.save();
                context.fillStyle = 'rgba(18,55,88,.2)';
                context.beginPath();
                context.arc(this.circle.x, this.circle.y, 245, 0, 2 * Math.PI, true);
                context.closePath();
                context.fill();
                context.restore();

                //外圆
                context.save();
                context.shadowBlur = 50;
                context.shadowColor = "#123959";
                context.fillStyle = '#080D27';
                context.beginPath();
                context.arc(this.circle.x, this.circle.y, 235, 0, 2 * Math.PI, true);
                context.closePath();
                context.fill();
                context.restore();
                for (let i = 0; i < this.title.length; i++) {//绘制文字。
                    context.save()
                    // 画文字
                    this.drawCircularText(this.circle, this.title[i], this.rads(i * 60 - 110), this.rads(i * 60 - 65), i, context);
                    context.restore();
                }
                // 旋转小球
                let x = 240 * Math.cos(everyPer);
                let y = 240 * Math.sin(everyPer);
                context.save();
                context.fillStyle = 'rgb(56,252,253)';
                context.shadowBlur = 80;
                context.shadowColor = "#39E9EE";
                context.translate(this.circle.x, this.circle.y);
                context.beginPath();
                context.arc(x, y, 5, 0, 2 * Math.PI);
                context.arc(-x, -y, 5, 0, 2 * Math.PI);
                context.closePath();
                context.fill();
                context.restore();
                //
                context.save();
                context.fillStyle = '#153776';
                context.beginPath();
                context.arc(this.circle.x, this.circle.y, 200, 0, 2 * Math.PI, true);
                context.closePath();
                context.fill();

                context.fillStyle = "#121535";
                context.beginPath();
                context.arc(this.circle.x, this.circle.y, 190, 0, 2 * Math.PI, true);
                context.closePath();
                context.fill();
                //内圆
                let nowRange = 73;
                context.save();
                this.drawCircle(context);
                this.drawSin(this.xOffset, context, nowRange);
                this.drawText(context, nowRange);
                context.restore();
                for (let i = 0; i < 6; i++) {//绘制刻度。
                    context.save();
                    context.translate(this.circle.x, this.circle.y);
                    context.rotate((-Math.PI / 2 + Math.PI / 6) + i * Math.PI / 3);  //旋转坐标轴。坐标轴x的正方形从 向上开始算起
                    context.beginPath();
                    context.moveTo(190, 0);
                    context.lineTo(200, 0);
                    context.lineWidth = 4;
                    context.strokeStyle = '#0A122D';
                    context.stroke();
                    context.closePath();
                    context.restore();
                }
            },
            drawCircle(ctx) { // 画圆 中心圆
                ctx.beginPath();
                ctx.fillStyle = '#209ADF';
                ctx.arc(this.circle.x, this.circle.y, 120, 0, 2 * Math.PI);
                ctx.fill();
                ctx.beginPath();
                ctx.arc(this.circle.x, this.circle.y, 120, 0, 2 * Math.PI);
                ctx.clip();
            },
            drawSin(xOffset, ctx, nowRange) { //画sin 曲线函数
                let mW = 240;
                let mH = 240;
                let sX = 0;
                let axisLength = mW; //轴长
                let waveWidth = 0.04; //波浪宽度,数越小越宽
                let waveHeight = 12; //波浪高度,数越大越高
                ctx.save();
                ctx.translate(130, 130);
                let points = []; //用于存放绘制Sin曲线的点
                ctx.beginPath();
                //在整个轴长上取点
                for (let x = sX; x < sX + axisLength; x += 20 / axisLength) {
                    //此处坐标(x,y)的取点，依靠公式 “振幅高*sin(x*振幅宽 + 振幅偏移量)”
                    let y = -Math.sin((sX + x) * waveWidth + xOffset);
                    let dY = mH * (1 - nowRange / 100);
                    points.push([x, dY, dY + y * waveHeight]);
                    ctx.lineTo(x, dY + y * waveHeight);
                }
                //封闭路径
                ctx.lineTo(axisLength, mH);
                ctx.lineTo(sX, mH);
                ctx.lineTo(points[0][0], points[0][1]);
                ctx.fillStyle = '#2C50B1';
                ctx.fill();

                ctx.restore();
            },
            // 中心显示文字
            drawText(ctx, nowRange) {
                ctx.save();
                ctx.translate(130, 130);
                let size = 50;
                ctx.font = size + 'px Microsoft Yahei';
                ctx.textAlign = 'center';
                ctx.fillStyle = "#95EFFF";
                ctx.fillText(nowRange + '%', 120, 120 - size / 2);
                ctx.restore();
                ctx.save()
                size = 25;
                ctx.translate(130, 130);
                ctx.font = size + 'px Microsoft Yahei';
                ctx.textAlign = 'center';
                ctx.fillStyle = "#95EFFF";
                ctx.fillText("安全指数", 120, 120 + size);
                ctx.restore();
            },
            // 旋转的文字
            drawCircularText(s, string, startAngle, endAngle, n, context) {
                let radius = s.radius, // 文字环绕的中心圆半径
                    angleDecrement, // 一个文字所占的角度
                    angle = parseFloat(startAngle), // 文字的起始角度
                    index = 0, // 文字的索引值
                    character; // 当前要画的文字
                let arr = string.split(':')
                context.save();
                context.fillStyle = '#6EDDF1';
                context.font = '12px 微软雅黑 ';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                if (n < 2 || n === 5) { // 上三个不需要反转的文字
                    while (index < string.length) {
                        character = string.charAt(index);
                        if (arr[0].indexOf(character) >= 0) {
                            if (arr[0].length > 6) {
                                angleDecrement = (startAngle - endAngle) / (string.length - 3)
                            } else {
                                angleDecrement = (startAngle - endAngle) / (string.length - 1)
                            }

                        } else {
                            angleDecrement = (startAngle - endAngle) / (string.length + 6)
                        }
                        context.save();
                        context.beginPath();
                        context.translate(s.x + Math.cos(angle) * radius,
                            s.y + Math.sin(angle) * radius);
                        context.rotate(Math.PI / 2 + angle);
                        context.fillText(character, 0, 0);
                        angle -= angleDecrement;
                        index++;
                        context.restore();
                    }
                } else { // 下面三个需要反转的文字
                    while (index < string.length) {
                        character = string.split("").reverse().join("").charAt(index);// 字符串反转
                        if (arr[1].indexOf(character) >= 0) {
                            angleDecrement = (startAngle - endAngle) / (string.length + 6)
                        } else {
                            if (arr[0].length > 6) {
                                angleDecrement = (startAngle - endAngle) / (string.length - 3)
                            } else {
                                angleDecrement = (startAngle - endAngle) / (string.length - 1)
                            }
                        }
                        context.save();
                        context.beginPath();
                        context.translate(s.x + Math.cos(angle) * radius,
                            s.y + Math.sin(angle) * radius);
                        context.rotate(-Math.PI / 2 + angle);// 旋转文字
                        context.fillText(character, 0, 0);
                        angle -= angleDecrement;
                        index++;
                        context.restore();
                    }
                }
                context.restore();
            },
        },
        mounted() {
            // this.$http
            //     .get('https://api.luojie-safe.cn/api/user/info')
            //     .then(
            // response=>{//successcallback
            //     //alert("成功！"+JSON.stringify(response));
            //     //alert("https成功！"+JSON.stringify(response.body.data));
            //     document.getElementById("realName").innerHTML=response.body.data.realName;
            //     document.getElementById("companyName").innerHTML=response.body.data.companyName;
            //     document.getElementById("secureName").innerHTML=response.body.data.realName;
            //     document.getElementById("empNums").innerHTML="56&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n" +
            //         "                              &nbsp;&nbsp;&nbsp;&nbsp;";
            //     document.getElementById("compAddress").innerHTML=response.body.data.compAddress;
            //     document.getElementById("compPhone").innerHTML=response.body.data.compPhone;
            //     console.log(JSON.stringify(response))//返回结果数据
            //
            //
            // },
            // response=>{//errorcallback
            //     alert("失败！");
            //     console.log(JSON.stringify(response))//错误信息
            // }),
            //this.infoInit(),
            this.resumeDynamic();
            //this.schoolSum();

            this.centerBox = {
                width: document.querySelector('#circlePie').offsetWidth,
                height: document.querySelector('#circlePie').offsetHeight
            }
            for (let i = 0; i < 200; i++) {
                // 随机200个运动的圆点
                let x = Math.random() * this.centerBox.width; // 随机的x偏移量
                let y = Math.random() * this.centerBox.height; // 随机y轴偏移量
                let xa = Math.random() * 2 - 1; // x轴运动速度
                let ya = Math.random() * 2 - 1; // y轴运动速度
                this.dots.push({
                    x: x,
                    y: y,
                    xa: xa,
                    ya: ya,
                    // 两个圆点之间需要连线的距离
                    max: 40
                })
            }
            this.act();
            this.drawDot();
            this.resizeFn = this.$debounce(() => {
                this.$refs.chinaMap.setMap();
                // 通过捕获系统的onresize事件触发我们需要执行的事件
                this.centerBox = {
                    width: document.querySelector('#circlePie').offsetWidth,
                    height: document.querySelector('#circlePie').offsetHeight
                }
                for (let i = 1; i < 13; i++) {
                    this.$refs['chart' + i].setChart();
                }
            }, 500)
            window.addEventListener('resize', this.resizeFn)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeFn)
            window.cancelAnimationFrame(this.animationFrame1)
            window.cancelAnimationFrame(this.animationFrame2)
        }
    }
</script>

<style lang="less" scoped>
    .page2 {
        height: 100%;
        width: 100%;
        padding: 14px 20px 20px;
        background: #03044A;
        overflow: hidden;
        .bb-box {
            /*padding-left: 50px;*/
            margin-left: 50px;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .cc-box {
            width: 100%;
            height: 100%;
            padding: 30px 50px;
            color: #9999af;
            .line {
                margin-bottom: 15px;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
            }
            span {
                color: #6EDDF1
            }
        }
        .dd-box {
            position: absolute;
            top: 30px;
            right: 20px;
            width: 20%;
            height: 85%;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .box {
                text-align: center;
                color: #6EDDF1;
                .pt {
                    color: yellowgreen;
                    font-size: 27px;
                }
            }

            .box1 {
                text-align: center;
                margin-bottom: 80px;
                color: #6EDDF1;
                .pt {
                    color: yellowgreen;
                    font-size: 27px;
                }
            }
        }
        .info-box {
            position: absolute;
            width: 50%;
            text-align: left;
            top: 30px;
            right: 10px;
            color: #9999af;
            p {
                margin-top: 5px
            }
        }
        .content {
            height: 65%;

            .ivu-col {
                height: 100%;
            }

            .circlePie {
                height: 100%;
                padding: 0 0 40px;
                text-align: center;
                position: relative;

                canvas {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                }

                #dot {
                    background: rgba(0, 0, 0, 0);
                }
            }

            .list {
                height: 48%;

                .left, .right {
                    background: #03044A;
                }

                .left, .right, .center {
                    width: 100%;
                    height: 90%;
                    border: 1px solid #0D2451;
                    position: relative;

                    #left1, #left2, #left3, #right1, #right2, #right3, #center2 {
                        height: 100%;
                    }

                    .chart-68 {
                        width: 68%;
                        height: 100%;
                        float: left;
                    }
                    .chart-100 {
                        width: 100%;
                        height: 100%;
                        float: left;
                    }
                    .chart-32 {
                        width: 32%;
                        height: 100%;
                        float: left;
                    }
                }
            }
        }

        .bottom-list {
            height: 35%;

            .ivu-col {
                height: 100%;

                .list {
                    height: 100%;
                    width: 100%;
                    padding-right: 1.5%;
                    float: left;

                    #bottom_4 {
                        padding: 0 20px;
                    }

                    .bottom {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #0D2451;
                        position: relative;
                    }
                }

                .right-bottom {
                    width: 100%;
                    padding-right: 0;

                    .bottom1 {
                        width: 100%;
                    }
                }
            }
        }

    }
</style>
